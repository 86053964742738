import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "../Pages/NotFound";
import Fallback from "../Pages/Fallback";

const App = () => {
  const Home = React.lazy(() => import("../Pages/Home"));
  const Work = React.lazy(() => import("../Pages/Work"));
  const WebDevelopment = React.lazy(() => import("../Pages/WebDevelopment"));
  const MobileDevelopment = React.lazy(() => import("../Pages/MobileDevelopment"));
  const UIUXDesign = React.lazy(() => import("../Pages/UIUXDesign"));
  const Blog = React.lazy(() => import("../Pages/Blog"));
  const AboutUs = React.lazy(() => import("../Pages/AboutUs"));
  const Hosting = React.lazy(() => import("../Pages/Hosting"));
  const PrivacyPolicy = React.lazy(() => import("../Pages/PrivacyPolicy"));
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route
            index
            path="/"
            element={
              <Home
                title={
                  "Jezsel Software – React, Vue, Angular, Node.js, and Mobile Design and Development"
                }
              />
            }
          />
          <Route
            path="/web-application-development"
            element={
              <WebDevelopment
                title={
                  "Data Driven Web Development – From Static to MVP – Jezsel Software"
                }
              />
            }
          />
          <Route
            path="/mobile-application-development"
            element={
              <MobileDevelopment
                title={
                  "Mobile Application Development – We Keep It Simple - Jezsel Software"
                }
              />
            }
          />
          <Route
            path="/ui-ux-design"
            element={
              <UIUXDesign
                title={
                  "Jezsel Software: UI/UX Design – We Create and Maintain Brand Identity"
                }
              />
            }
          />
          <Route
            path="/work"
            element={<Work title={"Case Studies – Jezsel Software"} />}
          />
          <Route
            path="/blog"
            element={
              <Blog
                title={
                  "Jezsel Software – React, Vue, Angular, Node.js, and Mobile Design and Development"
                }
              />
            }
          />
          <Route
            path="/about-us"
            element={<AboutUs title={"About Us – Jezsel Software"} />}
          />
          <Route
            path="/hosting"
            element={
              <Hosting
                title={
                  "Jezsel Software – React, Vue, Angular, Node.js, and Mobile Design and Development"
                }
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy title={"Privacy Policy – Jezsel Software"} />
            }
          />
          <Route
            path="/*"
            element={<NotFound title={"Jezsel Software Pvt Ltd"} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;