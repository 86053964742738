import React from "react";

export default function Fallback() {
  return (
    <div className="relative w-screen h-screen flex items-center bg-pink animate-pulse">
      <div className="fixed bg-pink top-0 left-0 w-full screen-padding py-2 h-[90px] shadow-lg animate-pulse flex justify-between items-center">
        <div className="w-[250px] lg:w-[350px] h-full rounded-md bg-gray-100 animate-pulse"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="hidden w-[100px] h-[30px] rounded-md bg-gray-100 animate-pulse lg:inline"></div>
        <div className="w-[50px] lg:w-[100px] h-[50px] rounded-md bg-gray-100 animate-pulse"></div>
      </div>
      <div className="w-full h-full screen-padding flex flex-col items-center justify-center">
        <div className="w-full max-w-lg h-20 bg-gray-100 animate-pulse rounded-md py-8"></div>
        <div className="w-11/12 max-w-xl h-8 bg-gray-100 animate-pulse rounded-md my-4"></div>
        <div className="w-[150px] h-[60px] rounded-md bg-gray-100 animate-pulse"></div>
      </div>
    </div>
  );
}
